// branding configuration
$publication: 'scottishdailyexpress';
$logo-dimensions: 600, 160;
$primary-color: #0C328C;

$brand-hue: 222;
$brand-saturation: 84%;

$brand-color: hsl($brand-hue, $brand-saturation, 30%);
$highlight: hsl($brand-hue, $brand-saturation, 52%);
$hint: hsl($brand-hue, $brand-saturation, 97%);
$lowlight: hsl($brand-hue, $brand-saturation, 12%);

$logo-name: 'logo-scottishdailyexpress';

// overrides
@mixin overrides() {
  .social-sites {
    @include social-icons(true);

    .icon {
      @include icons-background($white, $primary-color);
    }

  }
}
